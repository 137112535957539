import _ from 'lodash';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import classnames from 'classnames';
import React from 'react';

import { useClickAway } from 'react-use';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import Modal from 'react-modal';
import Markdown from 'markdown-to-jsx';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useSWR from 'swr';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import MaxWidthContainer from 'components/MaxWidthContainer';
import Menubar from 'components/Menubar';
import MobileTabBar from 'components/MobileTabBar';
import IndexBannerCarouselMobile from 'components/IndexBannerCarouselMobile';
import IndexCommentCarouselMobile from 'components/IndexCommentCarouselMobile';
import CommentPopup from 'components/CommentPopup';
import Footer from 'components/Footer';
import StrapiImg from 'components/StrapiImg';
import {
  MicroscopeSvg,
  RankingSvg,
  CircleArrowSvg,
  TrashSvg,
  ChevronLeftSvg,
  CrossSvg,
} from 'components/SorraSvg';

import api, { swrFetcher } from 'utils/api';
import { swrFetcher as wpSwrFetcher } from 'utils/wpApi';
import localStore from 'utils/localStore';

import { MixpanelConfig } from 'utils/mixpanelConfig';

import { useAppLaunch } from 'contexts/AppLaunch';
import { useUserAuth } from 'contexts/UserAuth';

import useRWD from 'hooks/useRWD';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const { useState, useEffect, useCallback, useRef } = React;

const Home = (props) => {
  const { seo } = props;
  const { isLg } = useRWD();
  const { isSignInStatusReady, user, getAuthorizationHeader } = useUserAuth();

  const router = useRouter();
  const searchInputRef = useRef(null);
  const mobileSearchInputRef = useRef(null);
  const searchAreaRef = useRef(null);

  const { isReady, pathname, query } = router;

  useEffect(() => {
    if (isReady && !_.isNil(query.search)) {
      setInstantKeyword(query.search);
      searchInputRef.current.focus();
      const params = new URLSearchParams(query);
      params.delete('search');
      router.replace({ pathname, query: params.toString() }, undefined, {
        shallow: true,
      });
    }
  }, [isReady, query]);

  const [menubarOpacity, setMenubarOpacity] = useState(0);
  // const [scrollPositionState, setScrollPositionState] = useState(0);

  useClickAway(searchAreaRef, () => {
    if (isLg) {
      setShowSearchModal(false);
      setSearchPlaceholder('');
      setShowClearHistory(false);
      searchInputRef.current.blur();
    }
  });

  useGSAP(() => {
    ScrollTrigger.create({
      scroller: '#app-wrapper',
      start: '1px',
      end: '+=190px',
      // markers: true,
      scrub: true,
      onUpdate: (self) => {
        setMenubarOpacity(self.progress);
      },
    });

    gsap.to('.search-container', {
      scrollTrigger: {
        scroller: '#app-wrapper',
        trigger: '.search-container',
        toggleActions: 'play none none none',
        // markers: true,
        start: isLg ? 'top 230px' : 'top 200px',
        end: '+=80px',
        scrub: true,
      },
      opacity: 0,
    });
    return () => {
      ScrollTrigger.killAll();
    };
  }, [isLg]);

  const {
    // categoryTree,
    secondCategories,
    secondCategoryMap,
    // searchSkinProblemKeywords,
    notifications,
    storeMap,
    productOriginMap,
    factorMap,
    reviewSourceMap,
    reviewTagMap,
  } = useAppLaunch();

  const carouselInterval = isLg ? 2000 : 1500;

  const { data: home, isLoading: isHomeLoading } = useSWR(
    '/api/v2/home?v=1',
    swrFetcher,
    {
      fallbackData: {
        new_reviews: [],
        promo_banners: [],
        promotions: [],
        sorra_popular_categories: [],
        seo_question: '',
        seo_recommend: '',
        // sorra_featured: [],
        // sorra_featured2: [],
      },
    }
  );

  const { data: sorraFeatureds, isLoading: isSorraFeaturedsLoading } = useSWR(
    '/api/v3/sorraFeatureds',
    swrFetcher,
    {
      fallbackData: {
        sorra_featured: [],
        sorra_featured2: [],
      },
    }
  );

  const [localSearchHistory, setLocalSearchHistory] = useState([]);
  useEffect(() => {
    if (isSignInStatusReady && !user) {
      const h = localStore.getSorraSearchHistory();
      setLocalSearchHistory(h);
    }
  }, [isSignInStatusReady, user]);

  const {
    data: searchHistory,
    mutate: mutateSearchHistory,
    isLoading: isSearchHistoryLoading,
  } = useSWR(
    user ? '/api/v3/search/history' : null,
    async (url) => {
      const r = await swrFetcher(url, getAuthorizationHeader);
      return _.filter(r, (h) => {
        return !_.isEmpty(_.trim(h));
      });
    },
    {
      fallbackData: localSearchHistory,
    }
  );

  const { data: topSearches, isLoading: isTopSearchesLoading } = useSWR(
    '/api/v3/search/topSearches',
    async (url) => {
      const data = await swrFetcher(url);
      return _.take(data, isLg ? 12 : 8);
    },
    {
      fallbackData: [],
    }
  );

  const [keyword, setKeyword] = useState('');
  const searchQuery = qs.stringify({
    keyword: _.trim(keyword),
  });
  const [instantKeyword, setInstantKeyword] = useState('');
  const debouncedSearchKeyword = useCallback(
    _.debounce((value) => {
      setKeyword(value);
    }, 300),
    [setKeyword]
  );

  useEffect(() => {
    debouncedSearchKeyword(instantKeyword);
  }, [instantKeyword]);
  const { data: recommendations, isLoading: isRecommendationsLoading } = useSWR(
    `/api/v3/search/recommendations?${searchQuery}`,
    swrFetcher,
    {
      fallbackData: [],
    }
  );

  const recommendationSecondCategories = _.reduce(
    recommendations,
    (acc, rec) => {
      const { second_categories } = rec;
      return _.uniq([...acc, ...second_categories]);
    },
    []
  );

  const [topSearchDisplayIdx, setTopSearchDisplayIdx] = useState(0);
  const [topSearchDisplayCount, setTopSearchDisplayCount] = useState(0);

  const [searchPlaceholder, setSearchPlaceholder] = useState('');

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showClearHistory, setShowClearHistory] = useState(false);

  useEffect(() => {
    setTopSearchDisplayCount(topSearches.length);
  }, [topSearches]);

  useEffect(() => {
    setTopSearchDisplayIdx(0);
    if (topSearchDisplayCount > 0) {
      if (showSearchModal && _.isEmpty(searchPlaceholder)) {
        setSearchPlaceholder(topSearches[0]);
      }
    }
  }, [topSearchDisplayCount]);

  useEffect(() => {
    if (
      topSearchDisplayIdx === 0 &&
      gsap.getProperty('.search-display', 'y') !== 0
    ) {
      gsap.to('.search-display', {
        y: -32 * topSearchDisplayCount,
        duration: 0.4,
        onComplete: () => {
          gsap.set('.search-display', {
            y: 0,
          });
        },
      });
    } else {
      gsap.to('.search-display', {
        y: -32 * topSearchDisplayIdx,
        duration: 0.4,
      });
    }
  }, [topSearchDisplayIdx, topSearchDisplayCount]);

  useEffect(() => {
    let interval = null;
    if (!showSearchModal) {
      interval = setInterval(() => {
        setTopSearchDisplayIdx((prev) => {
          return (prev + 1) % topSearchDisplayCount;
        });
      }, 3000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [showSearchModal, topSearchDisplayCount]);

  const {
    promo_banners,
    promotions,
    new_reviews,
    // sorra_featured,
    // sorra_featured2,
    sorra_popular_categories,
    seo_question,
    seo_recommend,
  } = home;

  const { sorra_featured, sorra_featured2 } = sorraFeatureds;

  const promoBanners = _.filter(promo_banners, (b) => {
    return _.get(b, 'image');
  });

  const { data: activities, isLoading: isActivitiesLoading } = useSWR(
    '/api/v3/activities',
    swrFetcher,
    {
      fallbackData: [],
    }
  );

  const { data: miniBanners, isLoading: isMiniBannersLoading } = useSWR(
    '/api/v3/home/miniBanners',
    swrFetcher,
    {
      fallbackData: [],
    }
  );

  const { data: rewards, isLoading: isRewardsLoading } = useSWR(
    '/api/v3/home/rewards',
    swrFetcher,
    {
      fallbackData: [],
    }
  );

  const { data: posts, isLoading: isPostsLoading } = useSWR(
    'wp-json/wp/v2/posts?page=1&per_page=4',
    wpSwrFetcher,
    { fallbackData: [] }
  );

  const { data: postCategories, isLoading: isPostCategoriesLoading } = useSWR(
    'wp-json/wp/v2/categories?per_page=30',
    wpSwrFetcher,
    { fallbackData: [] }
  );

  const postCategoriesMap = _.keyBy(postCategories, 'id');

  const chunkMiniBanners = _.chunk(miniBanners, 4);
  const newActivities = _.take(activities, 3);
  const [activeMiniBannerIndex, setActiveMiniBannerIndex] = useState(0);

  // sorra featured
  const [sorraFeatured, setSorraFeatured] = useState([]);
  const [selectedSorraFeatured, setSelectedSorraFeatured] = useState(null);
  const [selectedSorraFeaturedProducts, setSelectedSorraFeaturedProducts] =
    useState([]);
  const [
    selectedMobileSorraFeaturedProducts,
    setSelectedMobileSorraFeaturedProducts,
  ] = useState([]);

  const chunkSorraFeaturedProducts = _.chunk(selectedSorraFeaturedProducts, 4);
  const chunkMobileSorraFeaturedProducts = _.chunk(
    selectedMobileSorraFeaturedProducts,
    2
  );

  // sorra featured 2
  const [sorraFeatured2, setSorraFeatured2] = useState([]);
  const [selectedSorraFeatured2, setSelectedSorraFeatured2] = useState(null);
  const [selectedSorraFeatured2Products, setSelectedSorraFeatured2Products] =
    useState([]);
  const [
    selectedMobileSorraFeatured2Products,
    setSelectedMobileSorraFeatured2Products,
  ] = useState([]);

  const chunkSorraFeatured2Products = _.chunk(
    selectedSorraFeatured2Products,
    4
  );
  const chunkMobileSorraFeatured2Products = _.chunk(
    selectedMobileSorraFeatured2Products,
    2
  );

  useEffect(() => {
    if (sorraFeatured.length !== sorra_featured.length) {
      setSorraFeatured(sorra_featured);
      setSelectedSorraFeatured(0);
    }
    if (sorraFeatured2.length !== sorra_featured2.length) {
      setSorraFeatured2(sorra_featured2);
      setSelectedSorraFeatured2(0);
    }
  }, [sorra_featured, sorra_featured2]);

  useEffect(() => {
    if (sorraFeatured.length > 0) {
      const target = sorraFeatured[selectedSorraFeatured];
      if (target) {
        const products = target.products;
        const banner = target.sorra_featured_banner;
        if (banner) {
          banner.isBanner = true;
          const [a, b, c, ...rest] = products;
          const newProducts = _.compact([a, b, c, banner, ...rest]);
          setSelectedSorraFeaturedProducts(newProducts);
          setSelectedMobileSorraFeaturedProducts([banner, ...products]);
          return;
        } else {
          setSelectedSorraFeaturedProducts(products);
          setSelectedMobileSorraFeaturedProducts(products);
          return;
        }
      }
    }
    setSelectedSorraFeaturedProducts([]);
  }, [sorraFeatured, selectedSorraFeatured]);

  useEffect(() => {
    if (sorraFeatured2.length > 0) {
      const target = sorraFeatured2[selectedSorraFeatured2];
      if (target) {
        const products = target.products;
        const banner = target.sorra_featured_banner;
        if (banner) {
          banner.isBanner = true;
          const [a, b, c, ...rest] = products;
          const newProducts = _.compact([a, b, c, banner, ...rest]);
          setSelectedSorraFeatured2Products(newProducts);
          setSelectedMobileSorraFeatured2Products([banner, ...products]);
          return;
        } else {
          setSelectedSorraFeatured2Products(products);
          setSelectedMobileSorraFeatured2Products(products);
          return;
        }
      }
    }
    setSelectedSorraFeatured2Products([]);
  }, [sorraFeatured2, selectedSorraFeatured2]);

  const [bottomSuggestionsExpanded, setBottomSuggestionsExpanded] =
    useState(false);
  const [bottomFaqExpanded, setBottomFaqExpanded] = useState(false);

  const [isCommentModalShow, setIsCommentModalShow] = useState(false);

  useEffect(() => {
    if (showSearchModal) {
      document.querySelector('#app-wrapper').scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      document.querySelector('#app-wrapper').style.overflow = 'hidden';
    } else {
      document.querySelector('#app-wrapper').style.overflow = '';
    }
  }, [showSearchModal]);

  const routeToSearchPage = (keyword, secondCategoryId) => {
    if (!_.isEmpty(keyword)) {
      const mo = [keyword];
      const q = { keyword };
      if (!_.isNil(secondCategoryId) && secondCategoryMap[secondCategoryId]) {
        const sc = secondCategoryMap[secondCategoryId];
        q.categoryID = sc.id;
        q.category = sc.name;
        mo.unshift(sc.name);
      }
      // setShowSearchModal(false);
      MixpanelConfig.track('search_performed', { search: mo.join(' • ') });
      router.push(`/search?${qs.stringify(q)}`);
    }
  };

  const handleSearchEnter = () => {
    if (!keyword && topSearches[topSearchDisplayIdx]) {
      routeToSearchPage(topSearches[topSearchDisplayIdx]);
    } else if (!isRecommendationsLoading && recommendations.length) {
      routeToSearchPage(keyword);
    }
  };

  const handleClearSearchHistory = async () => {
    if (user) {
      const headers = await getAuthorizationHeader();
      await api.request({
        method: 'DELETE',
        url: '/api/v3/search/history',
        headers,
      });
      mutateSearchHistory();
      setShowClearHistory(false);
    } else {
      localStore.deleteSorraSearchHistory();
      setLocalSearchHistory([]);
    }
  };

  return (
    <div>
      <NextSeo title={seo.title} description={seo.description} />
      <Modal
        isOpen={showClearHistory && !isLg}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}
        className="flex h-full w-full items-center justify-center"
        overlayClassName="z-[65] absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-[#111928] bg-opacity-50"
      >
        <div className="relative rounded-2xl bg-white px-4 py-6">
          <button
            className="absolute right-4 top-4 h-5 w-5"
            onClick={() => {
              setShowClearHistory(false);
            }}
          >
            <CrossSvg />
          </button>
          <p className="px-[108px] text-base font-medium text-[#111928]">
            刪除全部歷史搜索
          </p>
          <div className="mt-9 flex h-12 w-full gap-x-2 text-base font-medium">
            <button
              className="flex flex-1 items-center justify-center rounded-lg border border-sorra-pink bg-white text-sorra-pink"
              onClick={() => {
                setShowClearHistory(false);
              }}
            >
              取消
            </button>
            <button
              className="flex flex-1 items-center justify-center rounded-lg bg-sorra-pink text-white"
              onClick={async () => {
                await handleClearSearchHistory();
                setShowClearHistory(false);
              }}
            >
              確認
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showSearchModal}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}
        className="p-safe-offset block h-full w-full bg-white lg:hidden"
        overlayClassName="z-[60] lg:z-[49] absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 w-full h-full bg-[#111928] bg-opacity-50"
        onAfterOpen={() => {
          if (!isLg) {
            mobileSearchInputRef.current.focus();
          }
        }}
      >
        <div className="relative flex h-full w-full flex-col py-6">
          <div className="flex h-12 w-full shrink-0 items-center gap-x-2 px-4">
            <button
              className="h-6 w-6 shrink-0"
              onClick={() => {
                setShowSearchModal(false);
                setSearchPlaceholder('');
              }}
            >
              <ChevronLeftSvg />
            </button>
            <div className="flex h-full grow items-center gap-x-4 rounded-lg border border-[#D1D5DB] pl-4 pr-2">
              <div className="h-6 w-6">
                <MicroscopeSvg />
              </div>
              <input
                ref={mobileSearchInputRef}
                className="w-full bg-transparent text-base font-medium text-[#111928] placeholder:text-[#9B9B9B] focus:outline-none"
                placeholder={searchPlaceholder}
                value={instantKeyword}
                onChange={(e) => {
                  setInstantKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    handleSearchEnter();
                  }
                }}
              ></input>
              {keyword && (
                <button
                  className="h-6 w-6"
                  onClick={() => {
                    setInstantKeyword('');
                  }}
                >
                  <CrossSvg color="#9B9B9B" />
                </button>
              )}
            </div>
          </div>
          <div className="no-scrollbar mt-6 min-h-0 w-full grow overflow-hidden">
            <div>
              {keyword && isRecommendationsLoading && (
                <div className="flex w-full items-center justify-center">
                  <img
                    className="h-[145px] w-[145px]"
                    src="/images/search-placeholder.gif"
                  ></img>
                </div>
              )}
              {keyword &&
                !isRecommendationsLoading &&
                recommendations.length === 0 && (
                  <div className="mt-6 flex w-full flex-col items-center justify-center gap-y-3">
                    <img
                      className="h-[73px] w-[73px]"
                      src="/svgs/search-empty.svg"
                    ></img>
                    <div className="font-medium text-sorra-gray-800">
                      沒有搜索結果...
                    </div>
                  </div>
                )}
              {!isRecommendationsLoading && recommendations.length > 0 && (
                <div className="pb-8">
                  <div className="flex flex-col gap-y-6 px-4 pb-3 text-sm font-medium text-[#4a4a4a]">
                    {_.take(recommendations, 5).map((recommendation, idx) => {
                      const { id, brand, name, en_name, matchedWords } =
                        recommendation;
                      const display = `${brand ? `${brand} ` : ''} ${name || en_name}`;
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={async () => {
                            const newKeyword = display;
                            if (user) {
                              try {
                                const headers = await getAuthorizationHeader();
                                await api.request({
                                  method: 'post',
                                  url: '/api/v3/search/history',
                                  headers,
                                  data: {
                                    keyword: newKeyword,
                                  },
                                });
                              } catch (_) {
                                // console.error(error);
                              }
                            } else {
                              localStore.addSorraSearchHistory(newKeyword);
                            }
                            window.location.href = `/product/${id}`;
                          }}
                        >
                          <Highlighter
                            highlightClassName="text-sorra-pink bg-transparent"
                            textToHighlight={display}
                            searchWords={matchedWords}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="h-px w-full bg-[#E7E7E7]" />
                  <div className="mt-4 px-4">
                    <p>
                      在分類下搜索關鍵字{' '}
                      <span className="font-medium text-sorra-pink">
                        “{keyword}”
                      </span>
                    </p>
                    <div className="mt-4 grid grid-cols-1 gap-y-6">
                      {_.take(recommendationSecondCategories, 6).map(
                        (secondCategoryId) => {
                          const secondCategoryName = secondCategoryMap[
                            secondCategoryId
                          ]
                            ? secondCategoryMap[secondCategoryId].name
                            : '';
                          return (
                            <div
                              key={secondCategoryId}
                              className="flex h-7 cursor-pointer items-center gap-x-1.5 rounded-full text-sm font-medium text-[#111928]"
                              onClick={() => {
                                routeToSearchPage(keyword, secondCategoryId);
                                // window.location.href = `/search?product=&category=${secondCategoryName}&categoryID=${secondCategoryId}&keyword=${keyword}`;
                              }}
                            >
                              <div className="h-4 w-4">
                                <CircleArrowSvg />
                              </div>
                              <p>{secondCategoryName}</p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!keyword && (
              <div className="px-4">
                {searchHistory.length > 0 && (
                  <div>
                    <div className="flex justify-between text-base">
                      <p className="font-medium text-[#111928]">歷史搜索</p>
                      <div className="flex gap-x-3">
                        <button
                          className="flex items-center gap-x-1 font-medium text-[#9b9b9b]"
                          onClick={() => {
                            setShowClearHistory(true);
                          }}
                        >
                          <div className="h-5 w-5">
                            <TrashSvg />
                          </div>
                          <p>清除</p>
                        </button>
                      </div>
                    </div>
                    <div className="flex-rwo mt-4 flex flex-wrap gap-x-1.5 gap-y-2">
                      {searchHistory.map((search, idx) => {
                        return (
                          <div
                            key={idx}
                            className="min-w-0 cursor-pointer items-center overflow-hidden truncate rounded-full border border-[#E7E7E7] px-3 py-1 text-xs font-medium text-[#4a4a4a]"
                            onClick={() => {
                              // setShowSearchModal(false);
                              // setSearchPlaceholder('');
                              routeToSearchPage(search);
                            }}
                          >
                            {search}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div
                  className={classnames({
                    'mt-6': searchHistory.length > 0,
                  })}
                >
                  <div className="flex justify-between">
                    <p className="font-medium text-[#111928]">🔥 大家都在找</p>
                    <button
                      className="flex items-center gap-x-1 font-medium text-sorra-pink"
                      onClick={() => {
                        router.push('/ranking');
                        // window.location.href = '/ranking';
                      }}
                    >
                      <div className="h-5 w-5">
                        <RankingSvg />
                      </div>
                      <p>美妝排行榜</p>
                    </button>
                  </div>
                  <div className="mt-5 grid grid-cols-1 gap-y-6">
                    {topSearches.map((search, idx) => {
                      return (
                        <div
                          key={idx}
                          className="flex h-6 cursor-pointer items-center gap-x-1 rounded-full text-sm font-medium text-[#111928]"
                          onClick={() => {
                            routeToSearchPage(search);
                            // router.push(`/search?keyword=${search}`);
                          }}
                        >
                          <div className="h-4 w-4">
                            <CircleArrowSvg />
                          </div>
                          <p>{search}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <main>
        <Menubar
          props={{
            homeExtend: true,
            // scrollPosition: menubarOpacity,
            sourceUI: 'Home',
          }}
          menubarOpacity={menubarOpacity}
          pressSearch={() => {
            // pressSearchProductInputFocus(true);
            searchInputRef.current.focus();
          }}
        />

        <div className="globals-centerContentsMargin disabledTop">
          <div
            className={
              "index-header relative bg-[url('/images/bg-header-new_mobile.jpg')] bg-cover bg-center lg:bg-[url('/images/bg-header-new.jpeg')]" +
              (notifications?.length > 0 ? ' withNoti' : '')
            }
            // id="index_header"
          >
            <MaxWidthContainer
              className={
                'index-header-maxWidthContainer relative flex h-full items-start justify-between' +
                (notifications?.length > 0 ? ' withNoti' : '')
              }
            >
              <div className="index-header-captions relative flex w-full grid-rows-1 flex-col items-center justify-center gap-y-2 lg:gap-y-4">
                <p className="text-center text-2xl font-bold lg:text-5xl">
                  Buy Less, Choose Well.
                </p>
                <p className="text-center text-base font-medium lg:text-2xl">
                  最真實美妝評論平台
                </p>
              </div>

              {/* search bar */}
              <div className="search-container absolute bottom-0 left-0 right-0 z-[50] flex w-full -translate-y-6 flex-col items-center lg:translate-y-[calc(100%-40px)]">
                <div
                  ref={searchAreaRef}
                  className="relative w-full max-w-screen-lg px-4 lg:px-0"
                >
                  <div
                    className={classnames(
                      'relative flex h-12 w-full items-center gap-x-4 rounded-lg bg-white p-4 shadow-lg lg:h-20',
                      {
                        'border border-sorra-pink': showSearchModal,
                      }
                    )}
                  >
                    <div className="flex min-w-0 grow items-center">
                      <div className="ml-0 h-6 w-6 lg:ml-4">
                        <MicroscopeSvg />
                      </div>
                      <div className="relative h-8 min-w-0 grow">
                        <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 overflow-hidden">
                          <div
                            className={classnames(
                              'search-display absolute left-4 top-0 text-base font-medium text-[#9B9B9B]',
                              {
                                hidden:
                                  showSearchModal || !_.isEmpty(instantKeyword),
                              }
                            )}
                          >
                            {topSearches.map((search, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="flex h-8 items-center"
                                >
                                  <p>{search}</p>
                                </div>
                              );
                            })}
                            {topSearches.length > 0 && (
                              <div className="flex h-8 items-center">
                                <p>{topSearches[0]}</p>
                              </div>
                            )}
                            {/* topSearches.length */}
                          </div>
                        </div>
                        <input
                          ref={searchInputRef}
                          className="search-input h-full w-full bg-transparent pl-4 text-base font-medium text-[#111928] placeholder:text-[#9B9B9B] focus:outline-none"
                          placeholder={searchPlaceholder}
                          value={instantKeyword}
                          onChange={(e) => {
                            setInstantKeyword(e.target.value);
                          }}
                          onFocus={() => {
                            setSearchPlaceholder(
                              _.get(topSearches, topSearchDisplayIdx) || ''
                            );
                            setShowSearchModal(true);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                              handleSearchEnter();
                            }
                          }}
                          // onBlur={() => {}}
                        ></input>
                      </div>
                    </div>
                    <button
                      className={classnames(
                        'hidden items-center gap-x-3 rounded-lg bg-sorra-pink px-8 py-3 font-medium text-white lg:flex',
                        {
                          '!bg-[#9B9B9B]':
                            keyword &&
                            !isRecommendationsLoading &&
                            recommendations.length === 0,
                        }
                      )}
                      disabled={
                        keyword &&
                        !isRecommendationsLoading &&
                        recommendations.length === 0
                      }
                      onClick={() => {
                        handleSearchEnter();
                      }}
                    >
                      <div className="h-5 w-5">
                        <MicroscopeSvg color="white" />
                      </div>
                      <p>搜索</p>
                    </button>
                  </div>
                  <div
                    className={classnames(
                      'mt-4 hidden overflow-hidden rounded-lg border border-sorra-pink bg-white lg:block',
                      {
                        '!hidden': !showSearchModal,
                      }
                    )}
                  >
                    <div>
                      {keyword && isRecommendationsLoading && (
                        <div className="flex w-full items-center justify-center py-11">
                          <img
                            className="h-[145px] w-[145px]"
                            src="/images/search-placeholder.gif"
                          ></img>
                        </div>
                      )}
                      {keyword &&
                        !isRecommendationsLoading &&
                        recommendations.length === 0 && (
                          <div className="flex w-full flex-col items-center justify-center gap-y-3 py-11">
                            <img
                              className="h-[73px] w-[73px]"
                              src="/svgs/search-empty.svg"
                            ></img>
                            <div className="font-medium text-sorra-gray-800">
                              沒有搜索結果...
                            </div>
                          </div>
                        )}
                      {!isRecommendationsLoading &&
                        recommendations.length > 0 && (
                          <div className="pb-8 pt-4">
                            <div className="flex flex-col gap-y-4 px-8 pb-4 text-sm font-medium text-[#4a4a4a]">
                              {_.take(recommendations, 5).map(
                                (recommendation, idx) => {
                                  const {
                                    id,
                                    brand,
                                    name,
                                    en_name,
                                    matchedWords,
                                  } = recommendation;
                                  const display = `${brand ? `${brand} ` : ''} ${name || en_name}`;
                                  return (
                                    <div
                                      className="cursor-pointer"
                                      onClick={async () => {
                                        const newKeyword = display;
                                        if (user) {
                                          try {
                                            const headers =
                                              await getAuthorizationHeader();
                                            await api.request({
                                              method: 'post',
                                              url: '/api/v3/search/history',
                                              headers,
                                              data: {
                                                keyword: newKeyword,
                                              },
                                            });
                                          } catch (_) {
                                            // console.error(error);
                                          }
                                        } else {
                                          localStore.addSorraSearchHistory(
                                            newKeyword
                                          );
                                        }
                                        window.location.href = `/product/${id}`;
                                      }}
                                    >
                                      <Highlighter
                                        highlightClassName="text-sorra-pink bg-transparent"
                                        textToHighlight={display}
                                        searchWords={matchedWords}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <div className="h-px w-full bg-[#E7E7E7]" />
                            <div className="mt-2 px-8">
                              <p>
                                在分類下搜索關鍵字{' '}
                                <span className="font-medium text-sorra-pink">
                                  “{keyword}”
                                </span>
                              </p>
                              <div className="mt-4 grid grid-cols-2 gap-x-2 gap-y-4">
                                {_.take(recommendationSecondCategories, 6).map(
                                  (secondCategoryId) => {
                                    const secondCategoryName =
                                      secondCategoryMap[secondCategoryId]
                                        ? secondCategoryMap[secondCategoryId]
                                            .name
                                        : '';
                                    return (
                                      <div
                                        key={secondCategoryId}
                                        className="flex h-7 cursor-pointer items-center gap-x-1.5 gap-y-4 rounded-full text-sm font-medium text-[#111928]"
                                        onClick={() => {
                                          routeToSearchPage(
                                            keyword,
                                            secondCategoryId
                                          );
                                          // router.push(
                                          //   `/search?product=&category=${secondCategoryName}&categoryID=${secondCategoryId}&keyword=${keyword}`
                                          // );
                                          // window.location.href = `/search?product=&category=${secondCategoryName}&categoryID=${secondCategoryId}&keyword=${keyword}`;
                                        }}
                                      >
                                        <div className="h-[13px] w-[14px]">
                                          <CircleArrowSvg />
                                        </div>
                                        <p>{secondCategoryName}</p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    {!keyword && (
                      <div className="px-8 pb-8 pt-5">
                        {searchHistory.length > 0 && (
                          <div>
                            <div className="flex justify-between text-base">
                              <p className="font-medium text-[#111928]">
                                歷史搜索
                              </p>
                              <div className="flex gap-x-3">
                                {showClearHistory && (
                                  <>
                                    <button
                                      className="flex items-center gap-x-1 font-medium text-[#9b9b9b]"
                                      onClick={() => {
                                        setShowClearHistory(false);
                                      }}
                                    >
                                      <p>取消</p>
                                    </button>
                                    <div className="h-6 w-px border border-[#9b9b9b] bg-[#9b9b9b]" />
                                    <button
                                      className="flex items-center gap-x-1 font-medium text-sorra-pink"
                                      onClick={async () => {
                                        await handleClearSearchHistory();
                                      }}
                                    >
                                      <div className="h-5 w-5">
                                        <TrashSvg color="#F16B7C" />
                                      </div>
                                      <p>確認清除</p>
                                    </button>
                                  </>
                                )}
                                {!showClearHistory && (
                                  <button
                                    className="flex items-center gap-x-1 font-medium text-[#9b9b9b]"
                                    onClick={() => {
                                      setShowClearHistory(true);
                                    }}
                                  >
                                    <div className="h-5 w-5">
                                      <TrashSvg />
                                    </div>
                                    <p>清除</p>
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="flex-rwo mt-4 flex flex-wrap gap-2">
                              {searchHistory.map((search, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    className="block cursor-pointer rounded-full border border-[#E7E7E7] px-3 py-1 text-sm font-medium text-[#4a4a4a]"
                                    onClick={() => {
                                      routeToSearchPage(search);
                                      // router.push(`/search?keyword=${search}`);
                                      // window.location.href = `/search?keyword=${search}`;
                                    }}
                                  >
                                    <p>{search}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        <div
                          className={classnames({
                            'mt-6': searchHistory.length > 0,
                          })}
                        >
                          <div className="flex justify-between">
                            <p className="font-medium text-[#111928]">
                              🔥 大家都在搜
                            </p>
                            <button
                              className="flex items-center gap-x-1 font-medium text-sorra-pink"
                              onClick={() => {
                                router.push('/ranking');
                                // window.location.href = '/ranking';
                              }}
                            >
                              <div className="h-5 w-5">
                                <RankingSvg />
                              </div>
                              <p>美妝排行榜</p>
                            </button>
                          </div>
                          <div className="mt-4 grid grid-cols-4 gap-x-2 gap-y-4">
                            {topSearches.map((search, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="flex h-7 cursor-pointer items-center gap-x-1.5 gap-y-4 rounded-full px-0.5 text-sm font-medium text-[#111928]"
                                  onClick={() => {
                                    routeToSearchPage(search);
                                    // router.push(`/search?keyword=${search}`);
                                    // window.location.href = `/search?keyword=${search}`;
                                  }}
                                >
                                  <div className="h-[13px] w-[14px]">
                                    <CircleArrowSvg />
                                  </div>
                                  <p>{search}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          {/* promoBanners */}
          <div
            className={
              'pt-6 lg:pt-[78px]' +
              (promoBanners.length == 0 && isHomeLoading == false
                ? ' hidden'
                : '')
            }
          >
            <MaxWidthContainer>
              <div className="relative block px-4">
                <div className="mx-auto hidden h-[350px] w-[1024px] lg:block">
                  {isHomeLoading ? (
                    <div className="h-full w-full">
                      <Skeleton className="h-full w-full" />
                    </div>
                  ) : (
                    <IndexBannerCarouselMobile
                      className="home-banner h-full w-full overflow-visible"
                      showArrows={false}
                    >
                      {promoBanners.map((element) => {
                        var backgroundImageURL = '';
                        var hrefURL = '';
                        var hrefNewTab = false;

                        if (element.image != null) {
                          backgroundImageURL = element.image.url;
                        }

                        if (element.url != null) {
                          hrefURL = element.url;
                        }

                        hrefNewTab = element.new_tab;

                        return (
                          <a
                            key={element.id}
                            href={!hrefURL ? '#' : hrefURL}
                            onClick={() => {
                              MixpanelConfig.track('Click', {
                                Component: 'Home Page Ad',
                                'Ad Name': element.name || null,
                                'Ad URL': element.url || null,
                              });
                            }}
                            target={hrefNewTab == true ? '_blank' : ''}
                          >
                            <div
                              className={
                                'index-banner-item-mobile relative h-full w-full px-6 py-8'
                              }
                              style={{
                                backgroundImage:
                                  'url(' + backgroundImageURL + ')',
                              }}
                            >
                              {element.id === 87 && (
                                <img
                                  className="h-px w-px"
                                  src="https://ad.doubleclick.net/ddm/trackimp/N1003017.4334518SORRAHKFACEBOOK/B32821884.407904241;dc_trk_aid=599933411;dc_trk_cid=225687372;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ltd=;dc_tdv=1"
                                />
                              )}
                            </div>
                          </a>
                        );
                      })}
                    </IndexBannerCarouselMobile>
                  )}
                </div>
                <div className="aspect-[343/193] w-full lg:hidden">
                  {isHomeLoading ? (
                    <div className="home-banner h-full w-full overflow-visible">
                      <div
                        className={
                          'index-banner-item-mobile relative h-full w-full'
                        }
                      >
                        {<Skeleton width={'100%'} height={'100%'} />}
                      </div>
                    </div>
                  ) : (
                    <IndexBannerCarouselMobile
                      className="home-banner h-full w-full overflow-visible"
                      showArrows={false}
                    >
                      {promoBanners.map((element) => {
                        var backgroundImageURL = '';
                        var hrefURL = '';
                        var hrefNewTab = false;

                        if (element.mobile_image != null) {
                          backgroundImageURL = element.mobile_image.url;
                        } else if (element.image != null) {
                          backgroundImageURL = element.image.url;
                        }

                        if (element.url != null) {
                          hrefURL = element.url;
                        }

                        hrefNewTab = element.new_tab;

                        return (
                          <a
                            key={element.id}
                            href={!hrefURL ? '#' : hrefURL}
                            onClick={() => {
                              MixpanelConfig.track('Click', {
                                Component: 'Home Page Ad',
                                'Ad Name': element.name || null,
                                'Ad URL': element.url || null,
                              });
                            }}
                            target={hrefNewTab == true ? '_blank' : ''}
                          >
                            <div
                              className={
                                'index-banner-item-mobile relative h-full w-full px-6 py-8'
                              }
                              style={{
                                backgroundImage:
                                  'url(' + backgroundImageURL + ')',
                              }}
                            >
                              {element.id === 87 && (
                                <img
                                  className="h-px w-px"
                                  src="https://ad.doubleclick.net/ddm/trackimp/N1003017.4334518SORRAHKFACEBOOK/B32821884.407904241;dc_trk_aid=599933411;dc_trk_cid=225687372;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ltd=;dc_tdv=1"
                                />
                              )}
                            </div>
                          </a>
                        );
                      })}
                    </IndexBannerCarouselMobile>
                  )}
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          {/* min banners */}
          <div
            className={
              'pt-12 lg:pt-20' +
              (miniBanners.length == 0 && isHomeLoading == false
                ? ' hidden'
                : '')
            }
          >
            {(isMiniBannersLoading || miniBanners.length > 0) && (
              <MaxWidthContainer className="px-4">
                <div className="text-xl font-bold text-[#111928] lg:text-2xl">
                  Sorra 精選
                </div>
                <div className="relative -mx-4 flex overflow-hidden pb-6 pr-20 pt-4 lg:hidden">
                  {isMiniBannersLoading ? (
                    <div className="flex h-[130px] w-full gap-x-2.5 overflow-visible pl-4">
                      {[0, 1].map((i) => {
                        return (
                          <Skeleton
                            key={i}
                            className="aspect-[306/170] h-full rounded-lg"
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mobile-mini-banner-carousel relative w-full pl-4">
                      <Carousel
                        swipeable={true}
                        emulateTouch={true}
                        autoPlay={true}
                        infiniteLoop={miniBanners.length > 1}
                        interval={carouselInterval}
                        stopOnHover={true}
                        showIndicators={false}
                        showArrows={false}
                        showStatus={false}
                        showThumbs={false}
                      >
                        {miniBanners.map((banner) => {
                          const url = _.get(banner, 'image.url', '');
                          return (
                            <div key={banner.id} className="pr-2.5">
                              <div
                                className="aspect-[306/170] cursor-pointer overflow-hidden rounded-lg"
                                onClick={() => {
                                  if (banner.url) {
                                    window.open(
                                      banner.url,
                                      banner.new_tab ? '_blank' : ''
                                    );
                                  }
                                }}
                              >
                                <StrapiImg
                                  src={url}
                                  className="h-full w-full object-cover"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>
                  )}
                </div>
                <div className="mini-banner-carousel relative hidden pb-10 pt-7 lg:block">
                  {isMiniBannersLoading ? (
                    <div className="grid grid-cols-4 gap-x-4">
                      {[0, 1, 2, 3].map((i) => {
                        return (
                          <Skeleton
                            key={i}
                            className="aspect-[306/170] rounded-lg"
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <Carousel
                      swipeable={true}
                      emulateTouch={true}
                      autoPlay={true}
                      infiniteLoop={chunkMiniBanners.length > 1}
                      interval={carouselInterval}
                      stopOnHover={true}
                      showIndicators={false}
                      showStatus={false}
                      showThumbs={false}
                      onChange={setActiveMiniBannerIndex}
                      renderArrowPrev={(clickHandler, hasPrev, labelPrev) => {
                        return (
                          activeMiniBannerIndex !== 0 && (
                            <button
                              onClick={clickHandler}
                              className="absolute -left-6 top-1/2 z-10 h-10 w-10 -translate-x-full -translate-y-1/2 rounded-full"
                              style={{
                                boxShadow:
                                  '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              <img
                                className="p-2.5"
                                src="/svgs/index-horizontalScroll-arrowLeft.svg"
                              ></img>
                            </button>
                          )
                        );
                      }}
                      renderArrowNext={(clickHandler, hasNext, labelNext) => {
                        return (
                          activeMiniBannerIndex !==
                            chunkMiniBanners.length - 1 && (
                            <button
                              onClick={clickHandler}
                              className="absolute -right-6 top-1/2 z-10 h-10 w-10 -translate-y-1/2 translate-x-full rounded-full"
                              style={{
                                boxShadow:
                                  '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              <img
                                className="p-2.5"
                                src="/svgs/index-horizontalScroll-arrowRight.svg"
                              ></img>
                            </button>
                          )
                        );
                      }}
                    >
                      {chunkMiniBanners.map((chunk, index) => {
                        return (
                          <div key={index} className="grid grid-cols-4 gap-x-4">
                            {chunk.map((banner) => {
                              const url = _.get(banner, 'image.url', '');
                              return (
                                <div
                                  key={banner.id}
                                  className="aspect-[306/170] cursor-pointer overflow-hidden rounded-lg"
                                  onClick={() => {
                                    if (banner.url) {
                                      window.open(
                                        banner.url,
                                        banner.new_tab ? '_blank' : ''
                                      );
                                    }
                                  }}
                                >
                                  <StrapiImg
                                    src={url}
                                    className="h-full w-full object-cover"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </Carousel>
                  )}
                </div>
              </MaxWidthContainer>
            )}

            {/* sorra featured */}
            {/*  */}
            {(sorraFeatured.length > 0 || isSorraFeaturedsLoading) && (
              <div>
                <MaxWidthContainer className="px-4 lg:px-0">
                  <div className="no-scrollbar mt-6 flex w-full flex-row gap-x-8 overflow-x-auto">
                    {sorraFeatured.map((sf2, idx) => {
                      return (
                        <div
                          key={sf2.id}
                          className="flex shrink-0 cursor-pointer flex-col gap-y-4"
                          onClick={() => {
                            setSelectedSorraFeatured(idx);
                          }}
                        >
                          <p
                            className={classnames(
                              'text-base lg:text-xl',
                              idx === selectedSorraFeatured
                                ? 'font-bold text-[#111928]'
                                : 'font-normal text-[#4a4a4a]'
                            )}
                          >
                            {sf2.name}
                          </p>
                          <div
                            className={classnames(
                              'h-[3px] rounded-full',
                              idx === selectedSorraFeatured
                                ? 'bg-sorra-pink'
                                : 'bg-transparent'
                            )}
                          />
                        </div>
                      );
                    })}
                  </div>
                </MaxWidthContainer>
                <div className="h-px w-full bg-gray-100"></div>

                <MaxWidthContainer
                  key={selectedSorraFeatured}
                  className="overflow-hidden px-4 pt-5 lg:px-0 lg:pt-8"
                >
                  <div className="mini-banner-carousel relative hidden lg:block">
                    {isHomeLoading ? (
                      <Carousel
                        swipeable={false}
                        emulateTouch={false}
                        stopOnHover={false}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                      >
                        <div className="grid grid-cols-4 gap-6">
                          {[0, 1, 2, 3].map((i) => {
                            return (
                              <div key={i} className="aspect-[302/521]">
                                <div className="h-full w-full overflow-hidden rounded-[10px] bg-[#FFFFFF]">
                                  <Skeleton width={'100%'} height={'100%'} />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Carousel>
                    ) : (
                      chunkSorraFeaturedProducts.length > 0 && (
                        <Carousel
                          swipeable={true}
                          emulateTouch={true}
                          stopOnHover={true}
                          showIndicators={false}
                          showStatus={false}
                          showThumbs={false}
                          renderArrowPrev={(
                            clickHandler,
                            hasPrev,
                            labelPrev
                          ) => {
                            return (
                              hasPrev && (
                                <button
                                  onClick={clickHandler}
                                  className="absolute -left-6 top-1/2 z-10 h-10 w-10 -translate-x-full -translate-y-1/2 rounded-full"
                                  style={{
                                    boxShadow:
                                      '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                                  }}
                                >
                                  <img
                                    className="p-2.5"
                                    src="/svgs/index-horizontalScroll-arrowLeft.svg"
                                  ></img>
                                </button>
                              )
                            );
                          }}
                          renderArrowNext={(
                            clickHandler,
                            hasNext,
                            labelNext
                          ) => {
                            return (
                              hasNext && (
                                <button
                                  onClick={clickHandler}
                                  className="absolute -right-6 top-1/2 z-10 h-10 w-10 -translate-y-1/2 translate-x-full rounded-full"
                                  style={{
                                    boxShadow:
                                      '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                                  }}
                                >
                                  <img
                                    className="p-2.5"
                                    src="/svgs/index-horizontalScroll-arrowRight.svg"
                                  ></img>
                                </button>
                              )
                            );
                          }}
                        >
                          {chunkSorraFeaturedProducts.map((chunk, idx) => {
                            return (
                              <div key={idx} className="grid grid-cols-4 gap-6">
                                {chunk.map((p) => {
                                  if (p.isBanner) {
                                    const image = p.image;
                                    return (
                                      <div
                                        key={`banner-${p.id}`}
                                        className="aspect-[302/521] w-full cursor-pointer overflow-hidden rounded-[10px] bg-white"
                                        onClick={() => {
                                          window.open(
                                            p.url,
                                            p.new_tab == true ? '_blank' : ''
                                          );
                                        }}
                                      >
                                        <StrapiImg
                                          src={image ? image.url : ''}
                                          className="h-full w-full object-cover"
                                        />
                                      </div>
                                    );
                                  } else {
                                    const image = _.head(p.images);
                                    const stores = p.product_stores || [];
                                    const store = _.head(stores);
                                    const rating = _.get(p, 'rating', null);
                                    const origin = productOriginMap[p.origin];
                                    const facts = origin
                                      ? []
                                      : _.take(p.facts, 1);
                                    const restFacts = _.drop(
                                      p.facts,
                                      facts.length
                                    );

                                    const storeObj = store
                                      ? storeMap[store.store]
                                      : null;
                                    return (
                                      <div
                                        key={`product-${p.id}`}
                                        className="flex cursor-pointer flex-col bg-white pb-5 text-left"
                                        onClick={() => {
                                          window.location = `/product/${p.id}`;
                                        }}
                                      >
                                        <div className="aspect-square overflow-hidden rounded-2xl bg-gray-50 bg-opacity-50">
                                          <StrapiImg
                                            src={
                                              image
                                                ? image.url
                                                : `${window.location.origin}/images/placeholders/product.png`
                                            }
                                            className="h-full w-full object-cover"
                                          />
                                        </div>
                                        <div className="gap-y-1 pt-6">
                                          <div className="flex flex-col gap-y-0.5">
                                            {_.get(p, 'brand.name') && (
                                              <p className="truncate text-sm text-gray-800">
                                                {p.brand.name}
                                              </p>
                                            )}
                                            {_.get(p, 'name') && (
                                              <p className="truncate text-base font-medium text-[#111928] lg:text-lg">
                                                {p.name}
                                              </p>
                                            )}
                                            {_.get(p, 'en_name') && (
                                              <p className="truncate text-base font-medium text-[#111928] lg:text-lg">
                                                {p.en_name}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        {rating && (
                                          <p className="truncate pt-1 text-lg font-medium text-sorra-pink">
                                            {`${
                                              rating.rate < 49
                                                ? '🤔'
                                                : rating.rate < 79
                                                  ? '👌'
                                                  : '❤️'
                                            } ${rating.rate}%`}
                                            <span className="font-regular text-base text-sorra-gray-500">
                                              {' (' + rating.total + ')'}
                                            </span>
                                          </p>
                                        )}
                                        <div className="pt-2" />
                                        <div className="flex flex-row flex-wrap items-center gap-2">
                                          {origin && (
                                            <div
                                              style={{
                                                background:
                                                  'rgba(252, 225, 229, 0.50)',
                                              }}
                                              className="rounded-md px-2 py-1 text-xs font-medium text-sorra-pink"
                                            >
                                              {origin.name}製
                                            </div>
                                          )}
                                          {facts.map((fact) => {
                                            return (
                                              <div
                                                key={fact.id}
                                                style={{
                                                  background:
                                                    'rgba(225, 244, 244, 0.50)',
                                                }}
                                                className="rounded-md px-2 py-1 text-xs font-medium text-sorra-blue"
                                              >
                                                {fact.name}
                                              </div>
                                            );
                                          })}
                                          {restFacts.length > 0 && (
                                            <div className="flex rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800">
                                              +{restFacts.length}
                                            </div>
                                          )}
                                          {/* <div className="grow" /> */}
                                        </div>
                                        <div className="mb-1 w-full pt-3 text-lg font-medium text-[#111928]">
                                          {storeObj && store.price && (
                                            <div className="flex items-center">
                                              <div className="mr-1 shrink-0">
                                                HK$ {store.price} @
                                              </div>
                                              {storeObj.image &&
                                              storeObj.image.url != '' ? (
                                                <div className="flex justify-start">
                                                  <img
                                                    className="h-[16px] object-contain pl-1"
                                                    src={storeObj.image.url}
                                                  ></img>
                                                </div>
                                              ) : (
                                                <div className="min-w-0 grow truncate font-medium text-sorra-gray-900">
                                                  {storeObj.name}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          {stores.length - 1 > 0 ? (
                                            <div className="mt-1 text-sm font-normal text-sorra-gray-800">
                                              {`還有${p.product_stores.length - 1}間商戶亦有售`}
                                            </div>
                                          ) : (
                                            <div className="mt-1 min-h-5" />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            );
                          })}
                        </Carousel>
                      )
                    )}
                  </div>
                  <div className="mobile-mini-banner-carousel relative block w-full lg:hidden">
                    {isHomeLoading ? (
                      <Carousel
                        swipeable={false}
                        emulateTouch={false}
                        stopOnHover={false}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                      >
                        <div className="grid grid-cols-2 gap-2.5 pr-2.5">
                          {[0, 1].map((i) => {
                            return (
                              <div key={i} className="aspect-[140/342]">
                                <div className="h-full w-full overflow-hidden rounded-[10px] bg-[#FFFFFF]">
                                  <Skeleton width={'100%'} height={'100%'} />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Carousel>
                    ) : (
                      chunkMobileSorraFeaturedProducts.length > 0 && (
                        <Carousel
                          swipeable={true}
                          emulateTouch={true}
                          stopOnHover={true}
                          showIndicators={false}
                          showStatus={false}
                          showThumbs={false}
                          showArrows={false}
                        >
                          {chunkMobileSorraFeaturedProducts.map(
                            (chunk, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="grid grid-cols-2 gap-2.5 pr-2.5"
                                >
                                  {chunk.map((p) => {
                                    if (p.isBanner) {
                                      const image = p.mobile_image;
                                      return (
                                        <div
                                          key={`banner-${p.id}`}
                                          className="aspect-[140/342] h-full w-full cursor-pointer overflow-hidden rounded-[10px]"
                                          onClick={() => {
                                            window.open(
                                              p.url,
                                              p.new_tab == true ? '_blank' : ''
                                            );
                                          }}
                                        >
                                          <StrapiImg
                                            src={image ? image.url : ''}
                                            className="h-full w-full object-cover"
                                          />
                                        </div>
                                      );
                                    } else {
                                      const image = _.head(p.images);
                                      const stores = p.product_stores || [];
                                      const store = _.head(stores);
                                      const rating = _.get(p, 'rating', null);
                                      const origin = productOriginMap[p.origin];
                                      const facts = origin
                                        ? []
                                        : _.take(p.facts, 1);
                                      const restFacts = _.drop(
                                        p.facts,
                                        facts.length
                                      );

                                      const storeObj = store
                                        ? storeMap[store.store]
                                        : null;
                                      return (
                                        <div
                                          key={`product-${p.id}`}
                                          className="flex cursor-pointer flex-col text-left"
                                          onClick={() => {
                                            window.location = `/product/${p.id}`;
                                          }}
                                        >
                                          <div className="aspect-square overflow-hidden rounded-2xl bg-gray-50 bg-opacity-50">
                                            <StrapiImg
                                              src={
                                                image
                                                  ? image.url
                                                  : `${window.location.origin}/images/placeholders/product.png`
                                              }
                                              className="h-full w-full object-cover"
                                            />
                                          </div>
                                          <div className="gap-y-1 pt-6">
                                            <div className="flex flex-col gap-y-0.5">
                                              {_.get(p, 'brand.name') && (
                                                <p className="truncate text-sm text-gray-800">
                                                  {p.brand.name}
                                                </p>
                                              )}
                                              {_.get(p, 'name') && (
                                                <p className="truncate text-sm font-medium text-[#111928]">
                                                  {p.name}
                                                </p>
                                              )}
                                              {_.get(p, 'en_name') && (
                                                <p className="truncate text-sm font-medium text-[#111928]">
                                                  {p.en_name}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          {/* <div className="grow" /> */}
                                          {rating && (
                                            <div className="mt-1 flex items-center">
                                              <p className="text-sm font-bold text-sorra-pink">
                                                {`${
                                                  rating.rate < 49
                                                    ? '🤔'
                                                    : rating.rate < 79
                                                      ? '👌'
                                                      : '❤️'
                                                } ${rating.rate}% `}
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                ({`${rating.total}`})
                                              </p>
                                            </div>
                                          )}
                                          <div className="mt-1.5 flex flex-row flex-wrap items-center gap-2">
                                            {origin && (
                                              <div
                                                style={{
                                                  background:
                                                    'rgba(252, 225, 229, 0.50)',
                                                }}
                                                className="rounded-md px-2 py-1 text-xs font-medium text-sorra-pink"
                                              >
                                                {origin.name}製
                                              </div>
                                            )}
                                            {facts.map((fact) => {
                                              return (
                                                <div
                                                  key={fact.id}
                                                  style={{
                                                    background:
                                                      'rgba(225, 244, 244, 0.50)',
                                                  }}
                                                  className="rounded-md px-2 py-1 text-xs font-medium text-sorra-blue"
                                                >
                                                  {fact.name}
                                                </div>
                                              );
                                            })}
                                            {restFacts.length > 0 && (
                                              <div className="flex rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800">
                                                +{restFacts.length}
                                              </div>
                                            )}
                                          </div>
                                          <div className="mt-1.5 w-full text-sm font-medium text-[#111928]">
                                            {storeObj && store.price && (
                                              <div className="flex items-center">
                                                <div className="mr-1 shrink-0">
                                                  HK$ {store.price} @
                                                </div>
                                                {storeObj.image &&
                                                storeObj.image.url != '' ? (
                                                  <div className="flex justify-start">
                                                    <img
                                                      className="h-[16px] object-contain pl-1"
                                                      src={storeObj.image.url}
                                                    ></img>
                                                  </div>
                                                ) : (
                                                  <div className="min-w-0 grow truncate text-sm text-sorra-gray-900">
                                                    {storeObj.name}
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                            {stores.length - 1 > 0 && (
                                              <div className="mt-1 text-xs font-normal text-sorra-gray-800">
                                                {`還有${p.product_stores.length - 1}間商戶亦有售`}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              );
                            }
                          )}
                        </Carousel>
                      )
                    )}
                  </div>
                </MaxWidthContainer>
              </div>
            )}
          </div>

          {/* new review */}
          <div
            className={
              'pt-6 lg:pt-20' +
              (new_reviews.length == 0 && isHomeLoading == false
                ? ' hidden'
                : '')
            }
          >
            <MaxWidthContainer className="px-4">
              <div
                className={
                  'text-xl font-bold text-sorra-gray-900 lg:text-2xl' +
                  (isHomeLoading == true ? ' hidden' : '')
                }
              >
                近期用後感
              </div>
            </MaxWidthContainer>
            {/* new reviews desktop */}
            <MaxWidthContainer className="relative mt-[18px] hidden h-[424px] px-4 lg:block">
              {isHomeLoading ? (
                <IndexCommentCarouselMobile
                  className="home-comments h-full w-full overflow-visible"
                  props={{ showArrows: true, showIndicators: true }}
                >
                  <div className="grid h-full w-full grid-cols-3 gap-x-[26px]">
                    {[0, 1, 2].map((idx) => {
                      return (
                        <div
                          key={idx}
                          className="index-comment-item relative h-full cursor-pointer"
                        >
                          <Skeleton width={'100%'} height={'100%'} />
                        </div>
                      );
                    })}
                  </div>
                </IndexCommentCarouselMobile>
              ) : (
                <IndexCommentCarouselMobile
                  className="home-comments h-full w-full overflow-visible"
                  props={{ showArrows: true, showIndicators: true }}
                >
                  {[0, 1, 2, 3, 4, 5].map((index) => {
                    return (
                      <div
                        key={index}
                        className="grid h-full w-full grid-cols-3 gap-x-[26px]"
                      >
                        {[0, 1, 2].map((i) => {
                          var factor = '';
                          var productRedirectID = '';
                          var productImageURL = '';
                          var productBrandName = '';
                          var productName = '';
                          var productNameEN = '';
                          var sorraUserPicture = '';
                          var maskName = '';
                          var ageString = '';
                          var commentCountString = '';
                          var rating = '';
                          var certImages = [];
                          var reviewTags = [];
                          var commentString = '';
                          var comparisonImages = [];
                          var timeString = '';
                          var source = '';
                          var isRealCert = false;
                          var isActivity = false;
                          var reviewId = '';

                          if (index * 3 + i < new_reviews.length) {
                            var reviewObject = new_reviews[index * 3 + i];

                            if (reviewObject.product.images != null) {
                              if (reviewObject.product.images.length > 0) {
                                productImageURL =
                                  reviewObject.product.images[0].url;
                              }
                            }

                            if (reviewObject.product.brand != null) {
                              productBrandName =
                                reviewObject.product.brand.name;
                            }

                            productRedirectID = reviewObject.product.id;
                            productName = reviewObject.product.name;
                            productNameEN = reviewObject.product.en_name;

                            if (reviewObject.sorra_user != null) {
                              if (reviewObject.sorra_user.picture != null) {
                                sorraUserPicture =
                                  reviewObject.sorra_user.picture;
                              }

                              if (reviewObject.sorra_user?.age_range) {
                                ageString += `| ${reviewObject.sorra_user.age_range} 歲`;
                              }

                              if (reviewObject.sorra_user?.review_count) {
                                commentCountString += `| ${reviewObject.sorra_user.review_count}則評價`;
                              }
                            }

                            maskName = reviewObject.mask_name;

                            const reviewFactorId = reviewObject.factors[0];
                            factor = factorMap[reviewFactorId]
                              ? factorMap[reviewFactorId].name
                              : '';

                            switch (reviewObject.score) {
                              case 0:
                                rating = '👌 中性';
                                break;

                              case 1:
                                rating = '❤️ 好評';
                                break;

                              case -1:
                                rating = '👿 差評';
                                break;

                              default:
                                break;
                            }

                            certImages = reviewObject.cert_images;
                            reviewTags = reviewObject.review_tags;
                            commentString = reviewObject.comment;
                            comparisonImages = reviewObject.comparison_images;
                            isRealCert = reviewObject.is_real_cert;
                            isActivity = reviewObject.activity ? true : false;

                            var time = new Date(reviewObject.created_at);
                            timeString =
                              time.getDate() +
                              '/' +
                              (time.getMonth() + 1) +
                              '/' +
                              time.getFullYear() +
                              ' ' +
                              (time.getHours() < 10
                                ? '0' + time.getHours()
                                : time.getHours()) +
                              ':' +
                              (time.getMinutes() < 10
                                ? '0' + time.getMinutes()
                                : time.getMinutes());

                            source = reviewSourceMap[reviewObject.source]
                              ? reviewSourceMap[reviewObject.source].name
                              : '';
                            if (source == 'user') {
                              source = 'Sorra官網';
                            } else if (source == 'ig') {
                              source = 'Sorra IG社群';
                            }

                            reviewId = reviewObject.id;
                          }

                          return (
                            <a
                              key={`${reviewId}`}
                              href={'/product/' + productRedirectID}
                              className="index-comment-item relative h-full cursor-pointer px-[20px] py-[16px]"
                            >
                              <div className="flex h-[88px] gap-x-[12px] rounded-[8px] bg-white px-[16px] py-[10px]">
                                <img
                                  src={
                                    productImageURL == ''
                                      ? '/images/placeholders/product.png'
                                      : productImageURL
                                  }
                                  className="index-comment-item-product-image rounded-[4px]"
                                ></img>
                                <div className="flex grow items-center">
                                  <div className="grid w-full">
                                    <div className="text-left text-xs text-sorra-gray-800">
                                      {productBrandName}
                                    </div>
                                    <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                                      {productName}
                                    </div>
                                    <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                                      {productNameEN}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-[22px] flex items-center gap-x-2 px-[4px]">
                                <div className="h-[40px] w-[40px]">
                                  {sorraUserPicture ? (
                                    <img
                                      className="h-full w-full rounded-full"
                                      src={sorraUserPicture}
                                    />
                                  ) : (
                                    <div className="flex h-full w-full items-center justify-center rounded-full bg-[#D6DDFB80] text-sorra-blue">
                                      {`${maskName.charAt(0)}${maskName.charAt(
                                        maskName.length - 1
                                      )}`}
                                    </div>
                                  )}
                                </div>
                                <div className="grow">
                                  <div className="text-left text-sm font-bold text-[#111928]">
                                    {maskName}
                                  </div>
                                  <div className="mt-1 text-left text-xs text-sorra-gray-800">
                                    {!factor ? '無註明' : factor} {ageString}{' '}
                                    {commentCountString}
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center gap-x-2 pt-4">
                                <span className="text-base font-bold text-[#111928]">
                                  {rating}
                                </span>

                                {isRealCert ? (
                                  <div className="cursor-default rounded-md bg-[#D6DDFB80] px-2 py-1 text-[12px] text-sorra-blue">
                                    真實用家認證
                                  </div>
                                ) : null}

                                {isActivity ? (
                                  <div className="cursor-default rounded-md bg-[#FFDCC680] px-2 py-1 text-[12px] text-[#FFA66D]">
                                    試用品
                                  </div>
                                ) : null}

                                {reviewTags.map((item) => {
                                  // const targetTag = arrReviewTagsState.find(
                                  //   (tagItem) => item == tagItem.id
                                  // );
                                  const { name, text_color, background_color } =
                                    reviewTagMap[item];
                                  return (
                                    <div
                                      key={name}
                                      style={{
                                        color: text_color, //targetTag.text_color,
                                        background: background_color, //targetTag.background_color,
                                      }}
                                      className={`cursor-default rounded-md px-2 py-1 text-[12px]`}
                                    >
                                      {name}
                                    </div>
                                  );
                                })}
                              </div>

                              <div className="mb-[16px] line-clamp-2 h-[52px] w-full px-[4px] pt-1 text-left text-base text-[#111928]">
                                {commentString}
                              </div>

                              {comparisonImages.length > 0 ||
                              certImages.length > 0 ? (
                                <div className="flex items-center space-x-2 px-[4px] pt-[16px]">
                                  {certImages.map((item, index) => {
                                    return (
                                      <img
                                        key={`cert_${index}_${item.id}`}
                                        src={item.url}
                                        className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                                      />
                                    );
                                  })}
                                  {comparisonImages.map((item, index) => {
                                    return (
                                      <img
                                        key={`comparison_${index}_${item.id}`}
                                        src={item.url}
                                        className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                                      />
                                    );
                                  })}
                                </div>
                              ) : null}

                              <div className="mb-0 place-self-end px-[4px] pt-[24px] text-left text-xs text-[#4A4A4A]">
                                {timeString} 在 {source} 評價
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    );
                  })}
                </IndexCommentCarouselMobile>
              )}
            </MaxWidthContainer>

            {/* new reviews mobile */}
            <div className="index-featured-outer">
              <MaxWidthContainer className="index-featured-inner mt-[18px] flex h-[424px] gap-x-[10px] px-4 lg:hidden">
                {isHomeLoading ? (
                  <>
                    {[0, 1, 2].map((index) => {
                      return (
                        <div
                          key={index}
                          className="index-comment-item relative h-full w-[300px] cursor-pointer"
                        >
                          <Skeleton width={'100%'} height={'100%'} />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {new_reviews.map((element, elementIndex) => {
                      var factor = '';
                      var productRedirectID = '';
                      var productImageURL = '';
                      var productBrandName = '';
                      var productName = '';
                      var productNameEN = '';
                      var sorraUserPicture = '';
                      var maskName = '';
                      var ageString = '';
                      var commentCountString = '';
                      var rating = '';
                      var certImages = [];
                      var reviewTags = [];
                      var commentString = '';
                      var comparisonImages = [];
                      var timeString = '';
                      var source = '';
                      var isRealCert = false;
                      var isActivity = false;

                      var reviewObject = element;

                      // console.log(reviewObject)

                      if (reviewObject.product.images != null) {
                        if (reviewObject.product.images.length > 0) {
                          productImageURL = reviewObject.product.images[0].url;
                        }
                      }

                      if (reviewObject.product.brand != null) {
                        productBrandName = reviewObject.product.brand.name;
                      }

                      productRedirectID = reviewObject.product.id;
                      productName = reviewObject.product.name;
                      productNameEN = reviewObject.product.en_name;

                      if (reviewObject.sorra_user != null) {
                        if (reviewObject.sorra_user.picture != null) {
                          sorraUserPicture = reviewObject.sorra_user.picture;
                        }

                        if (reviewObject.sorra_user?.age_range) {
                          ageString += `| ${reviewObject.sorra_user.age_range} 歲`;
                        }

                        if (reviewObject.sorra_user?.review_count) {
                          commentCountString += `| ${reviewObject.sorra_user.review_count}則評價`;
                        }
                      }

                      maskName = reviewObject.mask_name;

                      const reviewFactorId = reviewObject.factors[0];
                      factor = factorMap[reviewFactorId]
                        ? factorMap[reviewFactorId].name
                        : '';

                      switch (reviewObject.score) {
                        case 0:
                          rating = '👌 中性';
                          break;

                        case 1:
                          rating = '❤️ 好評';
                          break;

                        case -1:
                          rating = '👿 差評';
                          break;

                        default:
                          break;
                      }

                      certImages = reviewObject.cert_images;
                      reviewTags = reviewObject.review_tags;
                      commentString = reviewObject.comment;
                      comparisonImages = reviewObject.comparison_images;
                      isRealCert = reviewObject.is_real_cert;
                      isActivity = reviewObject.activity ? true : false;

                      var time = new Date(reviewObject.created_at);
                      timeString =
                        time.getDate() +
                        '/' +
                        (time.getMonth() + 1) +
                        '/' +
                        time.getFullYear() +
                        ' ' +
                        (time.getHours() < 10
                          ? '0' + time.getHours()
                          : time.getHours()) +
                        ':' +
                        (time.getMinutes() < 10
                          ? '0' + time.getMinutes()
                          : time.getMinutes());

                      source = reviewSourceMap[reviewObject.source]
                        ? reviewSourceMap[reviewObject.source].name
                        : '';
                      if (source == 'user') {
                        source = 'Sorra官網';
                      } else if (source == 'ig') {
                        source = 'Sorra IG社群';
                      }

                      return (
                        <div
                          key={`${elementIndex}-${productRedirectID}`}
                          className="index-comment-item relative h-full w-[300px] cursor-pointer px-[20px] py-[16px]"
                          onClick={() => {
                            window.location.href =
                              '/product/' + productRedirectID;
                          }}
                        >
                          <div className="flex h-[88px] gap-x-[12px] rounded-[8px] bg-white px-[16px] py-[10px]">
                            <img
                              src={
                                productImageURL == ''
                                  ? '/images/placeholders/product.png'
                                  : productImageURL
                              }
                              className="index-comment-item-product-image rounded-[4px]"
                            ></img>
                            <div className="flex grow items-center">
                              <div className="grid w-full">
                                <div className="text-left text-xs text-sorra-gray-800">
                                  {productBrandName}
                                </div>
                                <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                                  {productName}
                                </div>
                                <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                                  {productNameEN}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-[22px] flex items-center gap-x-2 px-[4px]">
                            <div className="h-[40px] w-[40px]">
                              {sorraUserPicture ? (
                                <img
                                  className="h-full w-full rounded-full"
                                  src={sorraUserPicture}
                                />
                              ) : (
                                <div className="flex h-full w-full items-center justify-center rounded-full bg-[#D6DDFB80] text-sorra-blue">
                                  {`${maskName.charAt(0)}${maskName.charAt(
                                    maskName.length - 1
                                  )}`}
                                </div>
                              )}
                            </div>
                            <div className="grow">
                              <div className="text-left text-sm font-bold text-[#111928]">
                                {maskName}
                              </div>
                              <div className="mt-1 text-left text-xs text-sorra-gray-800">
                                {!factor ? '無註明' : factor} {ageString}{' '}
                                {commentCountString}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-x-2 pt-4">
                            <span className="text-base font-bold text-[#111928]">
                              {rating}
                            </span>

                            {isRealCert ? (
                              <div className="cursor-default rounded-md bg-[#D6DDFB80] px-2 py-1 text-[12px] text-sorra-blue">
                                真實用家認證
                              </div>
                            ) : null}

                            {isActivity ? (
                              <div className="cursor-default rounded-md bg-[#FFDCC680] px-2 py-1 text-[12px] text-[#FFA66D]">
                                試用品
                              </div>
                            ) : null}

                            {reviewTags.map((item) => {
                              // const targetTag = arrReviewTagsState.find(
                              //   (tagItem) => item == tagItem.id
                              // );
                              const { name, text_color, background_color } =
                                reviewTagMap[item];
                              return (
                                <div
                                  key={name}
                                  style={{
                                    color: text_color, //targetTag.text_color,
                                    background: background_color, //targetTag.background_color,
                                  }}
                                  className={`cursor-default rounded-md px-2 py-1 text-[12px]`}
                                >
                                  {name}
                                </div>
                              );
                            })}
                          </div>

                          <div className="mb-[16px] line-clamp-2 h-[52px] w-full px-[4px] pt-1 text-left text-base text-[#111928]">
                            {commentString}
                          </div>

                          {comparisonImages.length > 0 ||
                          certImages.length > 0 ? (
                            <div className="flex items-center space-x-2 px-[4px] pt-[16px]">
                              {certImages.map((item, index) => (
                                <img
                                  key={`cert_${index}_${item.id}`}
                                  src={item.url}
                                  className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                                />
                              ))}
                              {comparisonImages.map((item, index) => (
                                <img
                                  key={`comparison_${index}_${item.id}`}
                                  src={item.url}
                                  className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                                />
                              ))}
                            </div>
                          ) : null}

                          <div className="mb-0 place-self-end px-[4px] pt-[24px] text-left text-xs text-[#4A4A4A]">
                            {timeString} 在 {source} 評價
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </MaxWidthContainer>
            </div>
          </div>
          <div className="relative mt-10 pb-12 lg:mt-24 lg:pb-16">
            <div className="absolute flex h-full w-full flex-col">
              <MaxWidthContainer className="aspect-[1290/128] shrink-0" />
              <div
                className="grow"
                style={{
                  background:
                    'radial-gradient(132.98% 110.07% at 93.4% 20.5%, rgba(251, 210, 216, 0.30) 3.61%, rgba(255, 237, 239, 0.30) 69.68%)',
                }}
              ></div>
            </div>
            <div className="relative">
              <MaxWidthContainer className="relative">
                <div className="hidden lg:block">
                  <div
                    className="relative aspect-[1290/256] cursor-pointer overflow-hidden rounded-lg"
                    onClick={() => {
                      window.location.href = '/membership';
                    }}
                  >
                    <img
                      className="h-full w-full object-cover"
                      src="/images/desktop_join_member.png"
                    />
                  </div>
                </div>
                <div className="block lg:hidden">
                  <div
                    className="relative aspect-[375/428] cursor-pointer"
                    onClick={() => {
                      window.location.href = '/membership';
                    }}
                  >
                    <img
                      className="h-full w-full object-cover"
                      src="/images/mobile_join_member.png"
                    />
                  </div>
                </div>
              </MaxWidthContainer>

              {newActivities.length > 0 && (
                <MaxWidthContainer className="px-4 pt-6 lg:px-0 lg:pt-11">
                  <div className="text-xl font-bold text-[#111928] lg:text-2xl">
                    會員免費試用
                  </div>
                  <div className="grid grid-cols-1 gap-3 pt-[22px] lg:grid-cols-3 lg:gap-6 lg:pt-7">
                    {newActivities.map((act) => {
                      const { id, name } = act;
                      const thumbnail = _.get(act, 'thumbnail.url');
                      return (
                        <div
                          key={id}
                          // href={`/campaign/${act.name}`}
                          className="flex cursor-pointer flex-col gap-y-4"
                          onClick={() => {
                            window.location.href = `/campaign/${name}`;
                          }}
                        >
                          <div className="relative aspect-[343/193] overflow-hidden rounded-lg lg:aspect-[405/228]">
                            <StrapiImg
                              src={thumbnail}
                              className="h-full w-full object-cover"
                            />
                            {id === 10 && (
                              <img
                                className="h-px w-px"
                                src="https://ad.doubleclick.net/ddm/trackimp/N1003017.4334518SORRAHKFACEBOOK/B32685117.406872372;dc_trk_aid=598558842;dc_trk_cid=224181113;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ltd=;dc_tdv=1"
                              />
                            )}
                            {id === 12 && (
                              <img
                                className="h-px w-px"
                                src="https://ad.doubleclick.net/ddm/trackimp/N1003017.4334518SORRAHKFACEBOOK/B32821884.407904241;dc_trk_aid=599933411;dc_trk_cid=225687372;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ltd=;dc_tdv=1"
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center justify-center pt-6 lg:pt-7">
                    <a
                      className="block w-full rounded-lg border border-sorra-pink px-5 py-2.5 text-center text-sm font-medium text-sorra-pink focus:outline-none focus:ring-4 focus:ring-blue-300 xl:w-[360px]"
                      href="/campaign"
                    >
                      查看全部
                    </a>
                  </div>
                </MaxWidthContainer>
              )}

              {rewards.length > 0 && (
                <MaxWidthContainer className="px-4 pt-6 lg:px-0 lg:pt-11">
                  <div className="text-xl font-bold text-[#111928] lg:text-2xl">
                    免費換領
                  </div>
                  <div className="grid grid-cols-2 gap-4 pt-[22px] lg:grid-cols-4 lg:gap-6 lg:pt-7">
                    {rewards.map((reward) => {
                      const { inventory, score, original_score } = reward;
                      return (
                        <div
                          key={reward.id}
                          className={classnames(
                            'flex flex-col',
                            inventory > 0 ? 'cursor-pointer' : ''
                          )}
                          onClick={() => {
                            if (inventory > 0) {
                              window.location.href = `/rewards/gift/${reward.id}`;
                            }
                          }}
                        >
                          <div className="aspect-square overflow-hidden rounded-2xl">
                            <StrapiImg
                              src={
                                _.get(reward, 'image.url') ||
                                `${window.location.origin}/images/placeholders/product.png`
                              }
                              className="h-full w-full object-cover"
                            />
                          </div>
                          <div className="mt-6 flex flex-col gap-y-1">
                            {_.get(reward, 'brand.name') && (
                              <p className="text-sm text-gray-800">
                                {reward.brand.name}
                              </p>
                            )}
                            {_.get(reward, 'en_name') && (
                              <p className="text-base font-medium text-[#111928] lg:text-lg">
                                {reward.name}
                              </p>
                            )}
                            {_.get(reward, 'name') && (
                              <p className="text-base font-medium text-[#111928] lg:text-lg">
                                {reward.en_name}
                              </p>
                            )}
                          </div>
                          <div className="grow" />
                          <div className="mt-4 min-h-12">
                            <div className="flex">
                              <img
                                src="/svgs/sorra-points-small.svg"
                                className="mr-2"
                              ></img>
                              <div className="flex gap-x-2 text-base font-medium text-sorra-gray-900">
                                <p
                                  className={
                                    reward.original_score > 0
                                      ? 'text-sorra-pink'
                                      : ''
                                  }
                                >
                                  {'$' + reward.score}
                                </p>
                                {reward.original_score > 0 && (
                                  <p className="font-normal line-through">{`$${reward.original_score}`}</p>
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                'mt-0.5 text-xs font-medium text-sorra-gray-800' +
                                (inventory == 0 || inventory >= 2
                                  ? ' hidden'
                                  : '')
                              }
                            >
                              剩餘最後{' '}
                              <span className="text-sorra-pink">
                                {inventory}
                              </span>{' '}
                              件產品
                            </div>
                            <div
                              className={
                                'mt-0.5 text-xs font-medium text-sorra-gray-800' +
                                (inventory > 0 ? ' hidden' : '')
                              }
                            >
                              換完了～ 請期待新一輪獎賞！
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center justify-center pt-6 lg:pt-7">
                    <a
                      className="block w-full rounded-lg border border-sorra-pink px-5 py-2.5 text-center text-sm font-medium text-sorra-pink focus:outline-none focus:ring-4 focus:ring-blue-300 xl:w-[360px]"
                      href="/rewards"
                    >
                      查看全部
                    </a>
                  </div>
                </MaxWidthContainer>
              )}
            </div>
          </div>

          {sorraFeatured2.length > 0 && (
            <div>
              <MaxWidthContainer className="px-4 pt-12 lg:px-0 lg:pt-14">
                <div className="text-xl font-bold text-[#111928] lg:text-2xl">
                  更多好物雷品發現！
                </div>
                <div className="no-scrollbar mt-6 flex w-full flex-row gap-x-8 overflow-x-auto">
                  {sorraFeatured2.map((sf2, idx) => {
                    return (
                      <div
                        key={sf2.id}
                        className="flex shrink-0 cursor-pointer flex-col gap-y-4"
                        onClick={() => {
                          setSelectedSorraFeatured2(idx);
                        }}
                      >
                        <p
                          className={classnames(
                            'text-base lg:text-xl',
                            idx === selectedSorraFeatured2
                              ? 'font-bold'
                              : 'font-normal'
                          )}
                        >
                          {sf2.name}
                        </p>
                        <div
                          className={classnames(
                            'h-[3px] rounded-full',
                            idx === selectedSorraFeatured2
                              ? 'bg-sorra-pink'
                              : 'bg-transparent'
                          )}
                        />
                      </div>
                    );
                  })}
                </div>
              </MaxWidthContainer>
              <div className="h-px w-full bg-gray-100"></div>

              <MaxWidthContainer
                key={selectedSorraFeatured2}
                className="overflow-hidden px-4 pt-5 lg:px-0 lg:pt-8"
              >
                {isLg ? (
                  <div className="mini-banner-carousel relative hidden lg:block">
                    {chunkSorraFeatured2Products.length > 0 && (
                      <Carousel
                        swipeable={true}
                        emulateTouch={true}
                        stopOnHover={true}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        renderArrowPrev={(clickHandler, hasPrev, labelPrev) => {
                          return (
                            hasPrev && (
                              <button
                                onClick={clickHandler}
                                className="absolute -left-6 top-1/2 z-10 h-10 w-10 -translate-x-full -translate-y-1/2 rounded-full"
                                style={{
                                  boxShadow:
                                    '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                                }}
                              >
                                <img
                                  className="p-2.5"
                                  src="/svgs/index-horizontalScroll-arrowLeft.svg"
                                ></img>
                              </button>
                            )
                          );
                        }}
                        renderArrowNext={(clickHandler, hasNext, labelNext) => {
                          return (
                            hasNext && (
                              <button
                                onClick={clickHandler}
                                className="absolute -right-6 top-1/2 z-10 h-10 w-10 -translate-y-1/2 translate-x-full rounded-full"
                                style={{
                                  boxShadow:
                                    '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                                }}
                              >
                                <img
                                  className="p-2.5"
                                  src="/svgs/index-horizontalScroll-arrowRight.svg"
                                ></img>
                              </button>
                            )
                          );
                        }}
                      >
                        {chunkSorraFeatured2Products.map((chunk, idx) => {
                          return (
                            <div key={idx} className="grid grid-cols-4 gap-6">
                              {chunk.map((p) => {
                                if (p.isBanner) {
                                  const image = p.image;
                                  return (
                                    <div
                                      key={`banner-${p.id}`}
                                      className="aspect-[301/541] h-full w-full cursor-pointer overflow-hidden rounded-[10px] bg-white"
                                      onClick={() => {
                                        window.open(
                                          p.url,
                                          p.new_tab == true ? '_blank' : ''
                                        );
                                      }}
                                    >
                                      <StrapiImg
                                        src={image ? image.url : ''}
                                        className="h-full w-full object-cover"
                                      />
                                    </div>
                                  );
                                } else {
                                  const image = _.head(p.images);
                                  const stores = p.product_stores || [];
                                  const store = _.head(stores);
                                  const rating = _.get(p, 'rating', null);
                                  const origin = productOriginMap[p.origin];
                                  const facts = origin
                                    ? []
                                    : _.take(p.facts, 1);
                                  const restFacts = _.drop(
                                    p.facts,
                                    facts.length
                                  );

                                  const storeObj = store
                                    ? storeMap[store.store]
                                    : null;
                                  return (
                                    <div
                                      key={`product-${p.id}`}
                                      className="flex cursor-pointer flex-col bg-white pb-5 text-left"
                                      onClick={() => {
                                        window.location = `/product/${p.id}`;
                                      }}
                                    >
                                      <div className="aspect-square overflow-hidden rounded-2xl bg-gray-50 bg-opacity-50">
                                        <StrapiImg
                                          src={
                                            image
                                              ? image.url
                                              : `${window.location.origin}/images/placeholders/product.png`
                                          }
                                          className="h-full w-full object-cover"
                                        />
                                      </div>
                                      <div className="gap-y-1 py-6">
                                        <div className="mt-6 flex flex-col gap-y-0.5">
                                          {_.get(p, 'brand.name') && (
                                            <p className="truncate text-sm text-gray-800">
                                              {p.brand.name}
                                            </p>
                                          )}
                                          {_.get(p, 'name') && (
                                            <p className="truncate text-base font-medium text-[#111928] lg:text-lg">
                                              {p.name}
                                            </p>
                                          )}
                                          {_.get(p, 'en_name') && (
                                            <p className="truncate text-base font-medium text-[#111928] lg:text-lg">
                                              {p.en_name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="grow" />
                                      <div className="mb-1 w-full text-lg text-[#111928]">
                                        {storeObj && store.price && (
                                          <div className="flex items-center">
                                            <div className="mr-1 shrink-0">
                                              HK$ {store.price} @
                                            </div>
                                            {storeObj.image &&
                                            storeObj.image.url != '' ? (
                                              <div className="flex justify-start">
                                                <img
                                                  className="h-[16px] object-contain pl-1"
                                                  src={storeObj.image.url}
                                                ></img>
                                              </div>
                                            ) : (
                                              <div className="min-w-0 grow truncate text-sm font-medium text-sorra-gray-900">
                                                {storeObj.name}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        {stores.length - 1 > 0 ? (
                                          <div className="mt-1 text-sm font-normal text-sorra-gray-800">
                                            {`還有${p.product_stores.length - 1}間商戶亦有售`}
                                          </div>
                                        ) : (
                                          <div className="mt-1 min-h-5" />
                                        )}
                                      </div>
                                      <div className="flex flex-row flex-wrap items-center gap-2">
                                        {origin && (
                                          <div
                                            style={{
                                              background:
                                                'rgba(252, 225, 229, 0.50)',
                                            }}
                                            className="rounded-md px-2 py-1 text-xs font-medium text-sorra-pink"
                                          >
                                            {origin.name}製
                                          </div>
                                        )}
                                        {facts.map((fact) => {
                                          return (
                                            <div
                                              key={fact.id}
                                              style={{
                                                background:
                                                  'rgba(225, 244, 244, 0.50)',
                                              }}
                                              className="rounded-md px-2 py-1 text-xs font-medium text-sorra-blue"
                                            >
                                              {fact.name}
                                            </div>
                                          );
                                        })}
                                        {restFacts.length > 0 && (
                                          <div className="flex rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800">
                                            +{restFacts.length}
                                          </div>
                                        )}
                                        <div className="grow" />
                                        {rating && (
                                          <p className="text-sm font-bold text-gray-800">{`${
                                            rating.rate < 49
                                              ? '🤔'
                                              : rating.rate < 79
                                                ? '👌'
                                                : '❤️'
                                          }${rating.rate}%`}</p>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                ) : (
                  <div className="mobile-mini-banner-carousel relative w-full">
                    {chunkMobileSorraFeatured2Products.length > 0 && (
                      <Carousel
                        swipeable={true}
                        emulateTouch={true}
                        stopOnHover={true}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        showArrows={false}
                      >
                        {chunkMobileSorraFeatured2Products.map((chunk, idx) => {
                          return (
                            <div
                              key={idx}
                              className="grid grid-cols-2 gap-2.5 pr-2.5"
                            >
                              {chunk.map((p) => {
                                if (p.isBanner) {
                                  const image = p.mobile_image;
                                  return (
                                    <div
                                      key={`banner-${p.id}`}
                                      className="aspect-[140/342] h-full w-full cursor-pointer overflow-hidden rounded-[10px]"
                                      onClick={() => {
                                        window.open(
                                          p.url,
                                          p.new_tab == true ? '_blank' : ''
                                        );
                                      }}
                                    >
                                      <StrapiImg
                                        src={image ? image.url : ''}
                                        className="h-full w-full object-cover"
                                      />
                                    </div>
                                  );
                                } else {
                                  const image = _.head(p.images);
                                  const stores = p.product_stores || [];
                                  const store = _.head(stores);
                                  const rating = _.get(p, 'rating', null);
                                  const origin = productOriginMap[p.origin];
                                  const facts = origin
                                    ? []
                                    : _.take(p.facts, 1);
                                  const restFacts = _.drop(
                                    p.facts,
                                    facts.length
                                  );

                                  const storeObj = store
                                    ? storeMap[store.store]
                                    : null;
                                  return (
                                    <div
                                      key={`product-${p.id}`}
                                      className="flex cursor-pointer flex-col text-left"
                                      onClick={() => {
                                        window.location = `/product/${p.id}`;
                                      }}
                                    >
                                      <div className="aspect-square overflow-hidden rounded-2xl bg-gray-50 bg-opacity-50">
                                        <StrapiImg
                                          src={
                                            image
                                              ? image.url
                                              : `${window.location.origin}/images/placeholders/product.png`
                                          }
                                          className="h-full w-full object-cover"
                                        />
                                      </div>
                                      <div className="gap-y-1 py-6">
                                        <div className="mt-6 flex flex-col gap-y-0.5">
                                          {_.get(p, 'brand.name') && (
                                            <p className="truncate text-sm text-gray-800">
                                              {p.brand.name}
                                            </p>
                                          )}
                                          {_.get(p, 'name') && (
                                            <p className="truncate text-sm font-medium text-[#111928]">
                                              {p.name}
                                            </p>
                                          )}
                                          {_.get(p, 'en_name') && (
                                            <p className="truncate text-sm font-medium text-[#111928]">
                                              {p.en_name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      {/* <div className="grow" /> */}
                                      {rating && (
                                        <div className="flex items-center">
                                          <p className="text-sm font-bold text-sorra-pink">
                                            {`${
                                              rating.rate < 49
                                                ? '🤔'
                                                : rating.rate < 79
                                                  ? '👌'
                                                  : '❤️'
                                            } ${rating.rate}% `}
                                          </p>
                                          <p className="text-xs text-gray-500">
                                            ({`${rating.total}`})
                                          </p>
                                        </div>
                                      )}
                                      <div className="mt-2.5 flex flex-row flex-wrap items-center gap-2">
                                        {origin && (
                                          <div
                                            style={{
                                              background:
                                                'rgba(252, 225, 229, 0.50)',
                                            }}
                                            className="rounded-md px-2 py-1 text-xs font-medium text-sorra-pink"
                                          >
                                            {origin.name}製
                                          </div>
                                        )}
                                        {facts.map((fact) => {
                                          return (
                                            <div
                                              key={fact.id}
                                              style={{
                                                background:
                                                  'rgba(225, 244, 244, 0.50)',
                                              }}
                                              className="rounded-md px-2 py-1 text-xs font-medium text-sorra-blue"
                                            >
                                              {fact.name}
                                            </div>
                                          );
                                        })}
                                        {restFacts.length > 0 && (
                                          <div className="flex rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800">
                                            +{restFacts.length}
                                          </div>
                                        )}
                                      </div>
                                      <div className="mt-3 w-full text-sm text-[#111928]">
                                        {storeObj && store.price && (
                                          <div className="flex items-center">
                                            <div className="mr-1 shrink-0">
                                              HK$ {store.price} @
                                            </div>
                                            {storeObj.image &&
                                            storeObj.image.url != '' ? (
                                              <div className="flex justify-start">
                                                <img
                                                  className="h-[16px] object-contain pl-1"
                                                  src={storeObj.image.url}
                                                ></img>
                                              </div>
                                            ) : (
                                              <div className="min-w-0 grow truncate text-sm font-medium text-sorra-gray-900">
                                                {storeObj.name}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        {stores.length - 1 > 0 && (
                                          <div className="mt-1 text-xs font-normal text-sorra-gray-800">
                                            {`還有${p.product_stores.length - 1}間商戶亦有售`}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                )}
              </MaxWidthContainer>
            </div>
          )}
          <div
            className={
              'pt-6 lg:pt-20' +
              (sorra_popular_categories.length == 0 ? ' hidden' : '')
            }
          >
            <MaxWidthContainer className="px-4">
              <div className="text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                熱門排行榜
              </div>
              <div className="mt-5 grid grid-cols-2 gap-3 lg:grid-cols-4 lg:gap-4">
                {sorra_popular_categories.map((category) => {
                  const { id, name } = category;
                  const image = category.image
                    ? _.get(category, 'image.url')
                    : '/images/placeholders/product.png';
                  return (
                    <div
                      key={category.id}
                      className="lg:rounded-2x flex aspect-[164/80] cursor-pointer items-center gap-x-1 rounded-lg bg-[#F5F5F5] bg-opacity-50 p-1 lg:aspect-[308/130] lg:p-4"
                      onClick={() => {
                        router.push(
                          `/search?category=${name}&categoryID=${id}`
                        );
                        // window.location.href = `/search?product=&category=${name}&categoryID=${id}&keyword=`;
                      }}
                    >
                      <div className="aspect-square w-[72px] shrink-0 lg:w-[110px]">
                        <img
                          src={image}
                          className="h-full w-full object-cover"
                        />
                      </div>
                      <div className="min-w-0 grow text-sm font-bold lg:text-2xl">
                        <p className="line-clamp-3">{name || ''}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                社群文章
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="grid grid-cols-2 gap-x-6 gap-y-6 px-4 lg:grid-cols-4 lg:gap-y-0 lg:px-0">
              {_.filter(posts, (post) => {
                const ogImage =
                  _.get(post, 'yoast_head_json.og_image', []) || [];
                return ogImage.length > 0;
              }).map((post, idx) => {
                // const image = _.get
                const { id, title, link, categories } = post;
                const imageUrl = _.head(
                  _.get(post, 'yoast_head_json.og_image', []) || []
                ).url;
                const categoryId = _.last(categories);
                const categoryName =
                  _.get(postCategoriesMap[categoryId], 'name', '') || '';

                return (
                  <a key={`${idx}:${id}`} className="block" href={link}>
                    <img
                      className="aspect-square w-full rounded-xl object-cover"
                      src={imageUrl}
                    ></img>
                    <div className="pt-6 text-sm text-sorra-gray-800">
                      {categoryName}
                    </div>
                    <div className="line-clamp-2 pt-1 text-lg font-medium text-sorra-gray-900">
                      {title.rendered.replaceAll('&amp;', '&')}
                    </div>
                  </a>
                );
              })}
            </MaxWidthContainer>

            <MaxWidthContainer className="flex items-center justify-center p-4 pt-8">
              <a
                type="button"
                className="mb-2 mr-2 w-full rounded-lg border border-sorra-pink px-5 py-2.5 text-center text-sm font-medium text-sorra-pink focus:outline-none focus:ring-4 focus:ring-blue-300 xl:w-[360px]"
                href="https://www.sorra.net/blog/"
              >
                查看全部
              </a>
            </MaxWidthContainer>
          </div>

          <div className="mt-6 bg-[#F5F5F5] lg:mt-20">
            <MaxWidthContainer className="px-4 pb-[36px] pt-[36px] lg:pb-[96px] lg:pt-[96px]">
              <div className="flex items-center justify-center">
                <div className="">
                  <div
                    className="text-center text-2xl font-bold leading-[150%] text-sorra-gray-900 lg:text-5xl"
                    style={{ lineHeight: 150 + '%' }}
                  >
                    2 分鐘完成註冊 即時解鎖會員福利！
                  </div>
                  <div className="mt-[24px] lg:mt-[65px]">
                    <div className="flex w-full flex-wrap place-items-center items-center justify-center gap-x-[34px] gap-y-[40px] lg:gap-x-[94px]">
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-00.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 1 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            免費註冊
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          所有資料保密，
                          <br />
                          無滋擾聯絡。
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-01.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 2 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            完成皮膚檔案
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          於會員專區完成
                          <br />
                          「詳細皮膚檔案」
                          <br />
                          獲取額外積分。
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-02.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 3 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            留評價賺積分
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          分享你的美妝體驗，
                          <br />
                          留評價賺取積分！
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-03.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 4 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            積分好禮 Get！
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          ✦$1 Sorra 積分兌 HK$1，換取品牌好物。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-[32px] text-center text-xl font-normal text-sorra-gray-900 lg:mt-[64px]">
                    <button
                      className="h-[48px] w-full rounded-[8px] bg-sorra-pink text-base font-medium text-[#fff] lg:w-[300px]"
                      onClick={() => {
                        MixpanelConfig.track('signinup_from_membership_CTA');
                        window.location.href = '/login';
                      }}
                    >
                      免費註冊
                    </button>
                  </div>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                合作優惠
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="grid grid-cols-2 gap-x-6 gap-y-6 px-4 pt-8 lg:grid-cols-4 lg:px-0">
              {/* {promotions} */}
              {promotions.map((promotion) => {
                const { id, item_codename } = promotion;
                return (
                  <a
                    key={id}
                    className="flex gap-x-1 text-sm text-sorra-gray-900"
                    href={/*element.url*/ 'rewards?tab=code'}
                  >
                    <img
                      src="/svgs/index-partnership-arrow.svg"
                      className="mb-[auto] mt-[2px]"
                    ></img>
                    {item_codename}
                  </a>
                );
              })}
              {/* {blockPromotionsState} */}
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                搜尋熱門美妝護膚分類
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="p-4 text-sm text-sorra-gray-900">
              {secondCategories.map((sc, idx) => {
                const { id, name } = sc;
                return (
                  <React.Fragment key={id}>
                    <span>{idx > 0 ? ' | ' : ''}</span>
                    <a
                      href={
                        '/search?product=&category=' +
                        name +
                        '&categoryID=' +
                        id +
                        '&keyword='
                      }
                    >
                      {name}
                    </a>
                  </React.Fragment>
                );
              })}
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div
                className="cursor-pointer items-center border-b-2 border-t-2 border-gray-100 py-6"
                onClick={() =>
                  setBottomSuggestionsExpanded(
                    bottomSuggestionsExpanded == true ? false : true
                  )
                }
              >
                <div className="flex">
                  <div className="text-2xl font-bold text-sorra-gray-900">
                    化妝護膚品推薦
                  </div>
                  <div className="bottom-1 right-0 ml-[auto] text-sm font-bold">
                    <img
                      src="/svgs/index-section-expand.svg"
                      className={
                        bottomSuggestionsExpanded == true ? 'hidden' : ''
                      }
                    ></img>
                    <img
                      src="/svgs/index-section-collapse.svg"
                      className={
                        bottomSuggestionsExpanded == true ? '' : 'hidden'
                      }
                    ></img>
                  </div>
                </div>

                <div
                  className={
                    'pt-6' +
                    (bottomSuggestionsExpanded == true ? '' : ' hidden')
                  }
                >
                  <Markdown>{seo_recommend || ''}</Markdown>
                </div>
              </div>

              <div
                className="cursor-pointer items-center border-b-2 border-gray-100 py-6"
                onClick={() =>
                  setBottomFaqExpanded(bottomFaqExpanded == true ? false : true)
                }
              >
                <div className="flex">
                  <div className="text-2xl font-bold text-sorra-gray-900">
                    常見問題
                  </div>
                  <div className="bottom-1 right-0 ml-[auto] text-sm font-bold">
                    <img
                      src="/svgs/index-section-expand.svg"
                      className={bottomFaqExpanded == true ? 'hidden' : ''}
                    ></img>
                    <img
                      src="/svgs/index-section-collapse.svg"
                      className={bottomFaqExpanded == true ? '' : 'hidden'}
                    ></img>
                  </div>
                </div>

                <div
                  className={
                    'pt-6' + (bottomFaqExpanded == true ? '' : ' hidden')
                  }
                >
                  <Markdown>{seo_question || ''}</Markdown>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-24"></div>

          <CommentPopup
            externalPopupShown={isCommentModalShow}
            externalProductID={null}
            externalProductBrandName={null}
            externalProductName={null}
            setExternalPopupShown={setIsCommentModalShow}
          />

          <Footer />

          <MobileTabBar props={{ highlighted: 'home' }} />
        </div>
      </main>
    </div>
  );
};

export default Home;

export async function getServerSideProps(ctx) {
  const defaultTitle =
    '護膚品好用推介/比較 | 化妝品推薦香港 | 最真實化妝護膚品評價 - Sorra';
  const defaultDescription =
    '尋找護膚品好用的推薦和比較？Sorra提供最詳實的產品評論、價格比較、成份分析和用戶意見。我們根據用戶評價和專業評測，比較各種化妝品和護膚品，幫助您做出明智的選擇。無論您需要什麼，從化妝品到護膚品，我們的平台都有您需要的資訊。立即探索，找到最適合您的產品！';

  try {
    const { data: homPageSeo } = await api.request({
      method: 'GET',
      url: `/api/seo/homePage`,
    });
    const {
      page_title: pageTitle = '',
      page_meta_description: pageDescription = '',
    } = homPageSeo;

    const title = pageTitle || defaultTitle;
    const description = pageDescription || defaultDescription;

    return {
      props: {
        seo: { title, description },
      },
    };
  } catch (e) {
    return {
      props: {
        seo: {
          title: defaultTitle,
          description: defaultDescription,
        },
      },
    };
  }
}
